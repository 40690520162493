import { gql } from '@apollo/client';
import axios from 'axios';

import analytics from 'analytics';
import Config from 'config';
import client from 'utils/client';
import { openPopupWindow } from 'utils/helper';
import { useRESTQuery, UseRESTQueryProps } from 'utils/rest';

import {
  CalendarMeeting,
  ConnectCustomClientIntegrationData,
  ConnectCustomSubdomainIntegrationData,
  ConnectSignInTokenIntegrationData,
  EntityMappingIntegration,
  ExternalEntityInfo,
  ExternalEntityType,
  GoogleBigQuerySchema,
  IntegrationSettings,
} from './types';
import { SearchResultData } from 'mosaiq/search/types';

const WINDOW_NAME = 'integration';

declare global {
  interface Window {
    integrationConnectedCallback?: () => void;
    integrationErrorCallback?: (reason?: any) => void;
    ChiliPiper?: any;
  }
}

type Params = Record<string, any>;

type QueryOptions = Omit<UseRESTQueryProps<Params>, 'url' | 'data' | 'method'>;

export const getConnectedApps = () => {
  return client.query({
    query: gql`
      query ConnectedApps {
        connected_integrations {
          service
        }
      }
    `,
  });
};

export const getConnectedApp = (service: string) => {
  return client.query({
    query: gql`
      query ConnectedApp($service: String) {
        connected_integrations(where: { service: { _eq: $service } }) {
          service
        }
      }
    `,
    variables: {
      service,
    },
  });
};

export const getIntegrationsSettings = () =>
  axios.get<{ data: Record<string, IntegrationSettings> }>(
    Config.envConfig.endpoints.integrations.settings,
  );

export const connectIntegrationForKey = (key: string, fromGridItem?: boolean) =>
  new Promise<string>((resolve, reject) =>
    axios(Config.envConfig.endpoints.integrations.auth.replace('{#app}', key), {
      params: { fromGridItem },
    })
      .then(({ data }) => {
        /**
         * We will use this cb later on when the integration process
         * has finished and the backend redirects to the web-client.
         */
        window.integrationConnectedCallback = () => resolve(key);

        //  We will use this cb later on when the integration process has an error
        window.integrationErrorCallback = reject;

        openPopupWindow({
          name: WINDOW_NAME,
          url: data.redirectUrl,
        });
      })
      .catch(() => {}),
  );

export const connectIntegrationForKeyWithToken = (
  key: string,
  token: string,
  fromGridItem?: boolean,
) =>
  axios({
    url: Config.envConfig.endpoints.ecosystem.auth.token.replace('{#provider}', key),
    method: 'POST',
    data: {
      token,
      fromGridItem,
    },
  });

export const connectIntegrationForKeyWithSignInToken = (
  key: string,
  data: ConnectSignInTokenIntegrationData,
) =>
  axios({
    url: Config.envConfig.endpoints.ecosystem.auth.signInToken.replace('{#provider}', key),
    method: 'POST',
    data,
  });

export const connectIntegrationForKeyWithCustomClient = (
  key: string,
  data: ConnectCustomClientIntegrationData,
) =>
  new Promise<string>((resolve, reject) =>
    axios({
      url: Config.envConfig.endpoints.integrations.authCustomClient.replace('{#app}', key),
      method: 'POST',
      data,
    })
      .then(({ data }) => {
        /**
         * We will use this cb later on when the integration process
         * has finished and the backend redirects to the web-client.
         */
        window.integrationConnectedCallback = () => resolve(key);

        //  We will use this cb later on when the integration process has an error
        window.integrationErrorCallback = reject;

        openPopupWindow({
          name: WINDOW_NAME,
          url: data.redirectUrl,
        });
      })
      .catch(() => {}),
  );

export const connectIntegrationForKeyWithCustomSubdomain = (
  key: string,
  data: ConnectCustomSubdomainIntegrationData,
) =>
  new Promise<string>((resolve, reject) =>
    axios({
      url: Config.envConfig.endpoints.integrations.authCustomSubdomain.replace('{#app}', key),
      method: 'POST',
      data,
    })
      .then(({ data }) => {
        /**
         * We will use this cb later on when the integration process
         * has finished and the backend redirects to the web-client.
         */
        window.integrationConnectedCallback = () => resolve(key);

        //  We will use this cb later on when the integration process has an error
        window.integrationErrorCallback = reject;

        openPopupWindow({
          name: WINDOW_NAME,
          url: data.redirectUrl,
        });
      })
      .catch(() => {}),
  );

export const connectIntegrationForKeyWithMimecast = (_key: string, fromGridItem?: boolean) =>
  axios({
    url: Config.envConfig.endpoints.ecosystem.auth.mimecast,
    method: 'POST',
    data: {
      fromGridItem,
    },
  });

export const disconnectIntegrationForKey = (key: ExternalEntityType) =>
  axios({
    url: Config.envConfig.endpoints.integrations.disconnect.replace('{#app}', key),
    method: 'POST',
  }).then(() => key);

export const blockIntegrationForKey = (key: string) =>
  axios({
    url: Config.envConfig.endpoints.integrations.block,
    method: 'DELETE',
    data: { integration: key },
  });

export const unblockIntegrationForKey = (key: string) =>
  axios({
    url: Config.envConfig.endpoints.integrations.unblock,
    method: 'POST',
    data: { integration: key },
  });

export const setIntegrationSettings = (key: string, settings: IntegrationSettings) =>
  axios<{ data: Record<string, IntegrationSettings> }>({
    url: Config.envConfig.endpoints.integrations.settings,
    method: 'PATCH',
    data: { [key]: settings },
  });

/**
 * When the integration has finished, our backend redirects to the
 * web-client. At that point we need to close the popup and either call
 * the cb we set in the connectIntegration function or reload the opener view.
 */
export const integrationInterceptor = () => {
  /**
   * Omit if the window name is not 'integration'.
   * This is the name we set in connectIntegration function.
   */
  if (window.name !== WINDOW_NAME && !window.opener) {
    return;
  }

  // @ts-ignore
  analytics.context = 'integrations';

  /**
   * Here we will handle the communication between backend and frontend
   * using queries in the redirect url. For now we just get the errors.
   */
  if (window.location.search) {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams) {
      const errorNumber = urlParams.get('errno');
      const errorMessage = urlParams.get('message');

      if (errorNumber) {
        // If the user cancels, we just close the popup
        if (errorNumber !== '102') {
          analytics.track('integration.error', { error: errorMessage });

          window.opener.integrationErrorCallback({
            message: errorMessage ?? 'Something went wrong, try later.',
          });
        }

        window.close();
        return;
      }
    }
  }

  // If there is no cb, refresh the parent view
  if (window.opener.integrationConnectedCallback) {
    window.opener.integrationConnectedCallback();
  } else {
    window.opener.location.reload();
  }

  window.close();
};

export const getMappingsForEntity = async (entity_id: string, provider?: string) => {
  //TODO: let's cache the connected ecosystem, invalidating the cache
  //whenever above methods is called.
  const providerQuery = provider ? `&provider=${provider}` : '';

  try {
    const { data: mappings } = await axios.get<EntityMappingIntegration[]>(
      Config.envConfig.endpoints.ecosystem.mapping.get
        .replace('{#entityId}', entity_id)
        .concat(providerQuery),
    );

    return mappings.reduce<Record<string, EntityMappingIntegration>>((ret, entry) => {
      ret[entry.integration] = entry;
      return ret;
    }, {});
  } catch (_) {
    return {};
  }
};

export const createEntityIntegrationMapping = (
  entityId: string,
  provider: ExternalEntityType,
  externalInfo?: ExternalEntityInfo,
  oldMappingId?: string,
) =>
  axios({
    url: Config.envConfig.endpoints.ecosystem.mapping.create,
    method: 'POST',
    data: {
      entity_id: entityId,
      provider,
      external_info: externalInfo,
      old_mapping_id: oldMappingId,
    },
  });

export const updateEntityIntegrationMapping = (entityId: string, payload: Record<string, any>) => {
  return axios({
    url: Config.envConfig.endpoints.ecosystem.mapping.update.replace('{#entityId}', entityId),
    method: 'PUT',
    data: payload,
  });
};

export const deleteEntityIntegrationMapping = (mappingId: string) =>
  axios({
    url: Config.envConfig.endpoints.ecosystem.mapping.delete.replace('{#mappingId}', mappingId),
    method: 'DELETE',
  });

const providerEndpoint = (provider: ExternalEntityType, type: string, query?: string) => {
  return `${Config.envConfig.endpoints.ecosystem.orchestration.providerType
    .replace('{#provider}', provider)
    .replace('{#type}', type)}${!!query ? `?query=${query}` : ''}`;
};

export const ecosystemCreate = (
  provider: ExternalEntityType,
  type: string,
  options: Record<string, any>,
  entityId?: string,
) =>
  axios.post(providerEndpoint(provider, type), {
    ...(entityId ? { entityId } : {}),
    options,
  });

export const importEndpoint = (
  origin: string,
  types: string[],
  payload: Record<string, any> = {},
) => {
  return axios.post(Config.envConfig.endpoints.ecosystem.import, {
    origin,
    types,
    ...payload,
  });
};

// NOTE: We make sure that `entityId` is present in all endpoints because
// we might need it to read token admins

export const fetchSlackWorkspace = ({ workspaceId }: Params, entityId?: string) =>
  axios.get(providerEndpoint('slack', 'workspace'), buildParams({ workspaceId, entityId }));

export const fetchSlackChannels = (entityId?: string) =>
  axios.get(providerEndpoint('slack', 'channels'), buildParams({ entityId }));

export const fetchSlackUsers = (entityId?: string) =>
  axios.get(providerEndpoint('slack', 'users'), buildParams({ entityId }));

export const fetchSlackSearchChannels = (entityId?: string) =>
  axios.get(providerEndpoint('slack_search', 'channels'), buildParams({ entityId }));

export const fetchSlackChannel = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('slack', 'channel'), buildParams({ externalId, entityId }));

export const fetchSlackUser = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('slack', 'user'), buildParams({ externalId, entityId }));

export const fetchAsanaWorkspaces = (entityId: string) =>
  axios(providerEndpoint('asana', 'workspaces'), buildParams({ entityId }));

export const fetchAsanaWorkspace = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'workspace'), buildParams({ workspaceId, entityId }));

export const fetchAsanaProjects = ({ workspaceId, externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'projects'), buildParams({ workspaceId, externalId, entityId }));

export const fetchAsanaTeamProjects = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'teamProjects'), buildParams({ externalId, entityId }));

export const fetchAsanaProject = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'project'), buildParams({ externalId, entityId }));

export const fetchAsanaMembers = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'members'), buildParams({ externalId, entityId }));

export const fetchAsanaStatus = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'status'), buildParams({ externalId, entityId }));

export const fetchAsanaTeams = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'teams'), buildParams({ workspaceId, entityId }));

export const fetchAsanaTeam = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'team'), buildParams({ externalId, entityId }));

export const fetchAsanaTaskCounts = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'taskCounts'), buildParams({ externalId, entityId }));

export const fetchAsanaTasks = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'tasks'), buildParams({ externalId, entityId }));

export const fetchAsanaTask = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('asana', 'task'), buildParams({ externalId, entityId }));

export const fetchAsanaUserTasks = () =>
  axios(providerEndpoint('asana', 'userTasks'), buildParams({}));

export const fetchAsanaProjectUserTasks = (
  { workspaceId, externalId }: Params,
  entityId?: string,
) =>
  axios(
    providerEndpoint('asana', 'projectUserTasks'),
    buildParams({ workspaceId, externalId, entityId }),
  );

export const fetchMondayItems = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('monday', 'items'), buildParams({ externalId, entityId }));

export const fetchMondayBoards = (entityId?: string) =>
  axios(providerEndpoint('monday', 'boards'), buildParams({ entityId }));

export const fetchMondayBoard = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('monday', 'board'), buildParams({ externalId, entityId }));

export const fetchGithubWorkspaces = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('github', 'workspaces'), buildParams({ externalId, entityId }));

export const fetchGithubRepo = ({ workspaceId, externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('github', 'repo'), buildParams({ workspaceId, externalId, entityId }));

export const fetchGithubRepos = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('github', 'repos'), buildParams({ workspaceId, entityId }));

export const fetchGithubRepoPrs = ({ externalId, workspaceId }: Params, entityId?: string) =>
  axios(
    providerEndpoint('github', 'pull_requests'),
    buildParams({ externalId, workspaceId, entityId }),
  );

export const fetchGithubCommitCheckRuns = ({ externalId }: Params) =>
  axios(
    providerEndpoint('github', 'commit_check_runs'),
    buildParams({
      externalId,
    }),
  );

export const fetchGithubPrComments = ({ externalId }: Params, entityId?: string) =>
  axios(
    providerEndpoint('github', 'pr_comments'),
    buildParams({
      entityId,
      externalId,
    }),
  );

export const fetchOneDriveFileTypes = (entityId?: string) =>
  axios(providerEndpoint('onedrive', 'fileTypes'), buildParams({ entityId }));

export const useMicrosoftCalendarMeetings = (params: Params, options?: QueryOptions) =>
  useRESTQuery<CalendarMeeting[], Params>({
    ...options,
    url: providerEndpoint('microsoft_calendar', 'meetings'),
    ...buildParams(params),
  });

export const fetchMicrosoftCalendarMeetings = (params: Params, entityId?: string) =>
  axios(providerEndpoint('microsoft_calendar', 'meetings'), buildParams({ entityId, ...params }));

export const fetchOneDriveRecentFiles = (entityId?: string) =>
  axios(providerEndpoint('onedrive', 'userRecentFiles'), buildParams({ entityId }));

export const fetchOneDriveFolders = (externalId: string, entityId?: string, query?: string) =>
  axios(providerEndpoint('onedrive', 'folders', query), buildParams({ entityId, externalId }));

export const fetchTrelloWorkspaces = (entityId?: string) =>
  axios(providerEndpoint('trello', 'workspaces'), buildParams({ entityId }));

export const fetchTrelloWorkspace = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('trello', 'workspace'), buildParams({ workspaceId, entityId }));

export const fetchTrelloBoards = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('trello', 'boards'), buildParams({ workspaceId, entityId }));

export const fetchTrelloBoard = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('trello', 'board'), buildParams({ externalId, entityId }));

export const fetchTrelloBoardLists = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('trello', 'lists'), buildParams({ externalId, entityId }));

export const fetchTrelloBoardList = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('trello', 'list'), buildParams({ externalId, entityId }));

export const fetchTrelloBoardListCards = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('trello', 'listCards'), buildParams({ externalId, entityId }));

export const fetchTrelloCards = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('trello', 'cards'), buildParams({ externalId, entityId }));

export const fetchTrelloCard = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('trello', 'card'), buildParams({ externalId, entityId }));

export const fetchJiraWorkspaces = (entityId?: string) =>
  axios(providerEndpoint('jira', 'workspaces'), buildParams({ entityId }));

export const fetchJiraWorkspace = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('jira', 'workspace'), buildParams({ workspaceId, entityId }));

export const fetchJiraProjects = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('jira', 'projects'), buildParams({ workspaceId, entityId }));

export const fetchJiraProject = ({ workspaceId, externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('jira', 'project'), buildParams({ workspaceId, externalId, entityId }));

export const fetchJiraProjectIssueTypes = (
  { workspaceId, externalId }: Params,
  entityId?: string,
) =>
  axios(providerEndpoint('jira', 'issuetypes'), buildParams({ workspaceId, externalId, entityId }));

export const fetchJiraProjectIssueType = ({ workspaceId, externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('jira', 'issuetype'), buildParams({ workspaceId, externalId, entityId }));

export const fetchJiraProjectUserIssues = (
  { workspaceId, externalId }: Params,
  entityId?: string,
) =>
  axios(
    providerEndpoint('jira', 'projectUserIssues'),
    buildParams({ workspaceId, externalId, entityId }),
  );

export const fetchJiraIssues = ({ workspaceId, externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('jira', 'issues'), buildParams({ workspaceId, externalId, entityId }));

export const fetchJiraUserIssues = () =>
  axios(providerEndpoint('jira', 'userIssues'), buildParams({}));

export const fetchSharepointColumnTypes = (entityId?: string) =>
  axios(providerEndpoint('sharepoint', 'columnTypes'), buildParams({ entityId }));

export const fetchSharepointSites = (externalId?: string) =>
  axios(providerEndpoint('sharepoint', 'sites'), buildParams({ externalId }));

export const fetchSharepointDocumentLibs = (externalId?: string) =>
  axios(providerEndpoint('sharepoint', 'documentLibraries'), buildParams({ externalId }));

export const fetchSharepointFolders = (externalId?: string) =>
  axios(providerEndpoint('sharepoint', 'folders'), buildParams({ externalId }));

export const fetchClickupWorkspace = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('clickup', 'workspace'), buildParams({ workspaceId, entityId }));

export const fetchClickupSpace = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('clickup', 'space'), buildParams({ externalId, entityId }));

export const fetchClickupList = ({ workspaceId, externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('clickup', 'list'), buildParams({ workspaceId, externalId, entityId }));

export const fetchClickupListTasks = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('clickup', 'listTasks'), buildParams({ externalId, entityId }));

export const fetchClickupListUserTasks = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('clickup', 'listUserTasks'), buildParams({ externalId, entityId }));

export const fetchClickupUserTasks = () =>
  axios(providerEndpoint('clickup', 'userTasks'), buildParams({}));

export const fetchClickupTask = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('clickup', 'task'), buildParams({ externalId, entityId }));

export const fetchClickupWorkspaces = (entityId?: string) =>
  axios(providerEndpoint('clickup', 'workspaces'), buildParams({ entityId }));

export const fetchClickupSpaces = ({ workspaceId }: Params, entityId?: string) =>
  axios(providerEndpoint('clickup', 'spaces'), buildParams({ workspaceId, entityId }));

export const fetchClickupSpaceLists = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('clickup', 'lists'), buildParams({ externalId, entityId }));

export const fetchLinearTeam = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('linear', 'team'), buildParams({ externalId, entityId }));

export const fetchLinearTeams = (entityId?: string) =>
  axios(providerEndpoint('linear', 'teams'), buildParams({ entityId }));

export const fetchLinearProject = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('linear', 'project'), buildParams({ externalId, entityId }));

export const fetchLinearTeamProjects = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('linear', 'teamProjects'), buildParams({ externalId, entityId }));

export const fetchLinearProjectIssues = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('linear', 'projectIssues'), buildParams({ externalId, entityId }));

export const fetchLinearProjectUserIssues = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('linear', 'projectUserIssues'), buildParams({ externalId, entityId }));

export const fetchLinearTeamIssues = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('linear', 'teamIssues'), buildParams({ externalId, entityId }));

export const fetchLinearTeamUserIssues = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('linear', 'teamUserIssues'), buildParams({ externalId, entityId }));

export const fetchLinearIssue = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('linear', 'issue'), buildParams({ externalId, entityId }));

export const fetchLinearUserIssues = () =>
  axios(providerEndpoint('linear', 'userIssues'), buildParams({}));

export const fetchShortcutProjects = (entityId?: string) =>
  axios(providerEndpoint('shortcut', 'projects'), buildParams({ entityId }));

export const fetchShortcutProject = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'project'), buildParams({ externalId, entityId }));

export const fetchShortcutGroup = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'group'), buildParams({ externalId, entityId }));

export const fetchShortcutGroups = (entityId?: string) =>
  axios(providerEndpoint('shortcut', 'groups'), buildParams({ entityId }));

export const fetchShortcutWorkflow = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'workflow'), buildParams({ externalId, entityId }));

export const fetchShortcutWorkflows = (entityId?: string) =>
  axios(providerEndpoint('shortcut', 'workflows'), buildParams({ entityId }));

export const fetchShortcutWorkflowStates = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'workflowStates'), buildParams({ externalId, entityId }));

export const fetchShortcutEpics = (entityId?: string) =>
  axios(providerEndpoint('shortcut', 'epics'), buildParams({ entityId }));

export const fetchShortcutEpic = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'epic'), buildParams({ externalId, entityId }));

export const fetchShortcutStories = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'stories'), buildParams({ externalId, entityId }));

export const fetchShortcutTeamStories = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'teamStories'), buildParams({ externalId, entityId }));

export const fetchShortcutEpicStories = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'epicStories'), buildParams({ externalId, entityId }));

export const fetchShortcutUserStories = () =>
  axios(providerEndpoint('shortcut', 'userStories'), buildParams({}));

export const fetchShortcutProjectUserStories = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'projectUserStories'), buildParams({ externalId, entityId }));

export const fetchShortcutTeamUserStories = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'teamUserStories'), buildParams({ externalId, entityId }));

export const fetchShortcutEpicUserStories = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('shortcut', 'epicUserStories'), buildParams({ externalId, entityId }));

// TODO: Cache all the info on the frontend

export const fetchGoogleBigQueryTables = (externalId?: string) =>
  axios(providerEndpoint('google_bigquery', 'tables'), buildParams({ externalId }));

export const fetchGoogleBigQueryProjects = (externalId?: string) =>
  axios(providerEndpoint('google_bigquery', 'projects'), buildParams({ externalId }));

export const fetchGoogleBigQueryDatasets = (externalId?: string) =>
  axios(providerEndpoint('google_bigquery', 'datasets'), buildParams({ externalId }));

export const fetchGoogleDrives = (entityId?: string) =>
  axios(providerEndpoint('google_drive', 'drives'), buildParams({ entityId }));

export const fetchGoogleDrive = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('google_drive', 'drive'), buildParams({ entityId, externalId }));

export const fetchGoogleFoldersFiles = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('google_drive', 'foldersfiles'), buildParams({ entityId, externalId }));

export const fetchGoogleFolders = (externalId: string, entityId?: string, query?: string) =>
  axios(providerEndpoint('google_drive', 'folders', query), buildParams({ entityId, externalId }));

export const fetchGoogleFolder = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('google_drive', 'folder'), buildParams({ entityId, externalId }));

export const fetchGoogleFile = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('google_drive', 'file'), buildParams({ entityId, externalId }));

export const fetchGoogleUserRecentFiles = (entityId?: string) =>
  axios(providerEndpoint('google_drive', 'userRecentFiles'), buildParams({ entityId }));

export const fetchGoogleBigQuerySchema = (connectionId: string, externalId: string) =>
  axios.get<GoogleBigQuerySchema>(
    providerEndpoint('google_bigquery', 'schema'),
    buildParams({ connectionId, externalId }),
  );

export const fetchDropboxFoldersFiles = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('dropbox', 'foldersfiles'), buildParams({ entityId, externalId }));

export const fetchDropboxFolders = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('dropbox', 'folders'), buildParams({ entityId, externalId }));

export const fetchDropboxFolder = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('dropbox', 'folder'), buildParams({ entityId, externalId }));

export const fetchDropboxFile = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('dropbox', 'file'), buildParams({ entityId, externalId }));

export const fetchBoxFoldersFiles = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('box', 'foldersfiles'), buildParams({ entityId, externalId }));

export const fetchBoxFolders = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('box', 'folders'), buildParams({ entityId, externalId }));

export const fetchBoxFolder = (externalId: string, entityId?: string) =>
  axios(providerEndpoint('box', 'folder'), buildParams({ entityId, externalId }));

export const fetchBoxUserRecentFiles = (entityId?: string) =>
  axios(providerEndpoint('box', 'userRecentFiles'), buildParams({ entityId }));

export const fetchGoogleCalendarMeetings = (params: Params, entityId?: string) =>
  axios(providerEndpoint('google_calendar', 'meetings'), buildParams({ entityId, ...params }));

export const fetchFigmaProjects = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('figma', 'projects'), buildParams({ externalId, entityId }));

export const fetchFigmaFiles = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('figma', 'files'), buildParams({ externalId, entityId }));

export const fetchFigmaFile = ({ externalId }: Params, entityId?: string) =>
  axios(providerEndpoint('figma', 'file'), buildParams({ entityId, externalId }));

export const fetchMimecastMessage = ({ externalId }: Params) =>
  axios(providerEndpoint('mimecast', 'message'), buildParams({ externalId }));

export const forwardMimecastMessage = ({ externalId, options }: Params) => {
  return axios.post(providerEndpoint('mimecast', 'forward-email'), {
    externalId,
    options,
  });
};

export const createJiraTask = async (payload: Params, { workspaceId }: Params, entityId?: string) =>
  axios.post(
    providerEndpoint('jira', 'task'),
    buildParams({ workspaceId, entityId, options: { payload } }),
  );

export const fetchResourcePreviewUrl = (provider: ExternalEntityType, resource: SearchResultData) =>
  axios.get(
    providerEndpoint(provider, 'resourcePreviewUrl'),
    buildParams({
      externalId: resource.id,
      workspaceId: resource.parent_entities?.find((entity) => entity.type === 'site')?.id,
    }),
  );

const buildParams = ({
  entityId,
  externalId,
  workspaceId,
  ...params
}: Params): { params: Params } => ({
  params: {
    ...(entityId ? { entityId } : {}),
    ...(externalId !== null ? { externalId } : {}),
    ...(workspaceId ? { workspaceId } : {}),
    ...params,
  },
});
